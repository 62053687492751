<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar :backgroundColor="colorBackground" id="sidebar">
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Operativa',
            icon: 'nc-icon nc-delivery-fast',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Envíos', path: '/franquicia/envios' }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Control', path: '/franquicia/control' }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'RMA', path: '/franquicia/rma' }"
          >
          </sidebar-item>
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Envíos Agrupados',
              path: '/franquicia/envios-agrupados',
            }"
          >
          </sidebar-item>
          <!-- <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Recogidas',
              path: '/franquicia/operativa/recogidas',
            }"
          >
          </sidebar-item> -->
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Recogidas',
            icon: 'nc-icon nc-notes',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Solicitadas',
              path: '/recogidas/pendiente_recogida',
            }"
          />
          <!-- <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Ptes Recoger',
              path: '/recogidas/asignada_en_reparto',
            }"
          /> -->
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Con Incidencia',
              path: '/recogidas/incidencia_recogida',
            }"
          >
          </sidebar-item>
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Finalizadas',
              path: '/recogidas/recogida_finalizada',
            }"
          >
          </sidebar-item>
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Otras Plazas',
              path: '/recogidas/propias_otras_plazas_recogida',
            }"
          >
          </sidebar-item>
        </sidebar-item>

        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Entregas',
            icon: 'nc-icon nc-app',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Ptes Asignar',
              path: '/entregas/depositado_en_destino',
            }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Ptes Entregar',
              path: '/entregas/en_reparto',
            }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Con Incidencia',
              path: '/entregas/incidencia_entrega',
            }"
          >
          </sidebar-item>
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Finalizadas',
              path: '/entregas/entregado',
            }"
          >
          </sidebar-item>
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Otras Plazas',
              path: '/entregas/propias_otras_plazas_entrega',
            }"
          >
          </sidebar-item>
        </sidebar-item>

        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Movilidad',
            icon: 'nc-icon nc-compass-05',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'General',
              path: '/franquicia/trafico',
            }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Recogidas', path: '/franquicia/recogidas' }"
          >
          </sidebar-item>
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Entregas',
              path: '/franquicia/entregas',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Partner',
            icon: 'nc-icon nc-globe-2',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Salidas',
              path: '/franquicia/partner/salidas',
            }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Salidas programadas',
              path: '/franquicia/partner/salidas-programadas',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Gestión de Incidencias',
            icon: 'nc-icon nc-settings-tool-66',
            path: '/franquicia/incidencias/historico',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Importaciones',
            icon: 'nc-icon nc-cloud-upload-94',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Envios', path: '/franquicia/importaciones' }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'RMA', path: '/franquicia/importacionesRma' }"
          />
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Clientes',
            icon: 'nc-icon nc-circle-09',
            path: '/franquicia/clientes',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Listado de Clientes',
              path: '/franquicia/clientes',
            }"
          />
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Facturación',
            icon: 'nc-icon nc-paper-2',
            path: '/franquicia/facturas',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Control de Valoraciones',
              path: '/franquicia/valoraciones',
            }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Albaranes', path: '/franquicia/albaranes' }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Facturas', path: '/franquicia/facturas' }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Tarifas', path: '/franquicia/tarifas' }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Notificaciones',
              path: '/franquicia/notificaciones',
            }"
          />
          <!-- <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Documentación',
              path: '/franquicia/documentacion',
            }"
          /> -->
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Gestión de Cartera',
            icon: 'nc-icon nc-credit-card',
            path: '/franquicia/gestionFacturas',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Efectos', path: '/franquicia/efectos' }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Remesas', path: '/franquicia/remesas' }"
          />
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Franquicia',
            icon: 'nc-icon nc-single-02',
            path: '/franquicia/usuarios',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Mis Datos', path: '/franquicia/misDatos' }"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{ name: 'Usuarios', path: '/franquicia/usuarios' }"
          />
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  data() {
    return {
      ocultarItem: "mostrarme",
      ro: null,
      colorBackground: "black",
    };
  },
  computed: {},
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        docClasses.add("perfect-scrollbar-off");
      } else {
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      }
    },
    onResize() {
      let val = document.getElementById("sidebar").clientWidth;
      if (val == 80) {
        this.ocultarItem = "ocultarme";
      } else {
        this.ocultarItem = "mostrarme";
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      this.colorBackground = "black";
    } else this.colorBackground = "purple";
    this.$sidebar.ocultarSideBar();
    /* document.body.classList.toggle('sidebar-mini') */
    this.initScrollbar();
    this.ro = new ResizeObserver(this.onResize);
    let side = document.getElementById("sidebar");
    this.ro.observe(side);
  },
  beforeUnmount() {
    let side = document.getElementById("sidebar");
    this.ro.unobserve(side);
  },
};
</script>
<style scoped></style>
