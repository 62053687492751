var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"backgroundColor":_vm.colorBackground,"id":"sidebar"}},[_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Operativa',
          icon: 'nc-icon nc-delivery-fast',
          path: '*',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Envíos', path: '/franquicia/envios' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Control', path: '/franquicia/control' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'RMA', path: '/franquicia/rma' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Envíos Agrupados',
            path: '/franquicia/envios-agrupados',
          }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Recogidas',
          icon: 'nc-icon nc-notes',
          path: '*',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Solicitadas',
            path: '/recogidas/pendiente_recogida',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Con Incidencia',
            path: '/recogidas/incidencia_recogida',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Finalizadas',
            path: '/recogidas/recogida_finalizada',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Otras Plazas',
            path: '/recogidas/propias_otras_plazas_recogida',
          }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Entregas',
          icon: 'nc-icon nc-app',
          path: '*',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Ptes Asignar',
            path: '/entregas/depositado_en_destino',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Ptes Entregar',
            path: '/entregas/en_reparto',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Con Incidencia',
            path: '/entregas/incidencia_entrega',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Finalizadas',
            path: '/entregas/entregado',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Otras Plazas',
            path: '/entregas/propias_otras_plazas_entrega',
          }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Movilidad',
          icon: 'nc-icon nc-compass-05',
          path: '*',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'General',
            path: '/franquicia/trafico',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Recogidas', path: '/franquicia/recogidas' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Entregas',
            path: '/franquicia/entregas',
          }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Partner',
          icon: 'nc-icon nc-globe-2',
          path: '*',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Salidas',
            path: '/franquicia/partner/salidas',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Salidas programadas',
            path: '/franquicia/partner/salidas-programadas',
          }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Gestión de Incidencias',
          icon: 'nc-icon nc-settings-tool-66',
          path: '/franquicia/incidencias/historico',
        }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Importaciones',
          icon: 'nc-icon nc-cloud-upload-94',
          path: '*',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Envios', path: '/franquicia/importaciones' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'RMA', path: '/franquicia/importacionesRma' }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Clientes',
          icon: 'nc-icon nc-circle-09',
          path: '/franquicia/clientes',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Listado de Clientes',
            path: '/franquicia/clientes',
          }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Facturación',
          icon: 'nc-icon nc-paper-2',
          path: '/franquicia/facturas',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Control de Valoraciones',
            path: '/franquicia/valoraciones',
          }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Albaranes', path: '/franquicia/albaranes' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Facturas', path: '/franquicia/facturas' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Tarifas', path: '/franquicia/tarifas' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Notificaciones',
            path: '/franquicia/notificaciones',
          }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Gestión de Cartera',
          icon: 'nc-icon nc-credit-card',
          path: '/franquicia/gestionFacturas',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Efectos', path: '/franquicia/efectos' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Remesas', path: '/franquicia/remesas' }}})],1),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Franquicia',
          icon: 'nc-icon nc-single-02',
          path: '/franquicia/usuarios',
        }}},[_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Mis Datos', path: '/franquicia/misDatos' }}}),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Usuarios', path: '/franquicia/usuarios' }}})],1)],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{on:{"click":_vm.toggleSidebar}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }